<template>
  <div class="login-page">
    <div class="columns is-gapless is-flex is-multiline">
      <div
        class="column form-column is-5-fullhd is-5-widescreen is-6-desktop is-8-tablet is-12-mobile"
      >
        <div class="card">
          <div class="card-image logo">
            <img src="@/assets/logo_imfd_2.png" :alt="$t('navbar.logoAlt')" />
          </div>
          <div class="card-content">
            <p class="title">
              {{ $t('views.login.title') }}
            </p>
            <message-error
              v-if="showErrorMessage"
              :title="$t('views.login.failTitleMessage')"
              :detail="errorMessage"
            />
            <message-error
              v-if="errorPasswordRecovery"
              :title="$t('views.login.failPasswordTitle')"
              :detail="$t('views.login.failPasswordBody')"
            />
            <b-modal v-model="isSuccessModalActive">
              <div class="card-modal">
                <h3 class="title is-4">{{ $t('views.login.successModalTitle') }}</h3>
                <p>{{ $t('views.login.successModalMessage') }}</p>
                <p v-html="$t('views.login.successModalFooter')"></p>
              </div>
            </b-modal>
            <v-form :on-submit="login" :on-validation-error="onValidationError">
              <template v-slot="{ form }">
                <b-field
                  :label="$t('views.login.emailLabel')"
                  :type="form.types.email"
                  :message="form.errors.email"
                >
                  <b-input type="text" v-model="form.values.email" />
                </b-field>

                <b-field
                  :label="$t('views.login.passwordLabel')"
                  :type="form.types.password"
                  :message="form.errors.password"
                  style="margin-bottom: 0"
                >
                  <b-input type="password" v-model="form.values.password" />
                </b-field>
                <a @click="passwordRecovery(form.values.email)">
                  {{ $t('views.login.forgotPassword') }}
                </a>

                <b-button
                  native-type="submit"
                  type="is-info"
                  expanded
                  class="submit-login is-azul-imfd"
                >
                  {{ $t('views.login.button') }}
                </b-button>
              </template>
            </v-form>
          </div>
          <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
        </div>
      </div>
      <div
        class="column is-7-fullhd is-7-widescreen is-6-desktop is-4-tablet is-1-mobile login-background"
        :style="{
          'background-image': `url(${require('@/assets/images/patrones_bg_imfd.png')})`
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import VForm from '@/components/VForm'
import { createNamespacedHelpers } from 'vuex'
import { userLogIn } from '@/requests/auth'
import MessageError from '@/components/MessageError'
import AppError from '@/errors/AppError'
import { requestPasswordRecovery } from '@/requests/people'
import FormValidationError from '@/errors/FormValidationError'

const { mapMutations, mapState } = createNamespacedHelpers('auth')

export default {
  components: {
    VForm,
    MessageError
  },

  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      errorMessage: null,
      errorPasswordRecovery: null,
      isSuccessModalActive: false
    }
  },

  methods: {
    ...mapMutations(['logUserIn']),
    async passwordRecovery(email) {
      if (!email) {
        this.errorPasswordRecovery = true
        return
      }
      try {
        await requestPasswordRecovery({ email })
        this.isSuccessModalActive = true
      } catch (e) {
        if (e instanceof FormValidationError) {
          this.$buefy.toast.open({
            message: e.report.detail[0].msg,
            type: 'is-danger',
            position: 'is-top'
          })
        } else {
          throw new AppError()
        }
      }
    },

    async login(credentials) {
      this.isLoading = true

      try {
        await userLogIn(credentials)
        await this.$store.dispatch('auth/getProfile')
      } catch (err) {
        if (err instanceof AppError) {
          this.showErrorMessage = true
          this.errorMessage = err.message
        }
      } finally {
        this.isLoading = false
        if (this.userLoggedIn) {
          this.$router.push({ name: 'profile' })
        }
      }
    },
    onValidationError() {
      // it's guaranteed that err is instance of FormValidationError
      this.$buefy.toast.open({
        message: this.$t('views.login.incorrectCredentials'),
        type: 'is-danger',
        position: 'is-top'
      })
    }
  },
  computed: {
    ...mapState({ userLoggedIn: 'loggedIn' })
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/imfd/colors.scss';

.login-page {
  height: 100vh;

  .columns {
    height: 100%;
  }

  .form-column {
    background: white;
  }

  .login-container {
    height: 100vh;
  }

  .logo {
    width: 40%;
  }

  .login-background {
    background-size: cover;
    background-origin: box;
    background-repeat: repeat-y;
    background-position: right;
    /*
    unfortunately background images cannot be ran through the asset bundler. bummer!
    background-image: url(@/assets/images/patron_imfd.png);
     */
  }

  .card-modal {
    background-color: white;
    margin-top: 5vh;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 5vh;

    .card-content {
      margin-top: 20vh;
      padding: 0;
    }
  }

  .card {
    background-color: white;
    //max-width: 500px;
    margin-right: auto;
    text-align: left;
    padding: 4em;

    .card-content {
      margin-top: 20vh;
      padding: 0;
    }
  }

  .submit-login {
    margin-top: 3vh;
  }

  > div {
    flex: 1;
  }

  a {
    color: $azul-imfd;
  }

  a:hover {
    color: black;
  }
}
</style>
